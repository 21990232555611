<!--
 * @Author: fengjingwei
 * @Date: 2021-09-13 20:16:34
 * @Description: 电梯统计数据分析
-->
<template>
  <div class="statistic-container">
    <div class="box-container sm-container">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" style="width: 100%">
            <a-form-model-item label="时间范围" prop="starttime" style="margin-left: 15%;">
              <a-range-picker v-model="timerange" style="">
              </a-range-picker>
            </a-form-model-item>
<!--            <a-form-model-item label="事件类型" prop="alarmtype" style="margin-left: 4%;width: 30%">-->
<!--              <a-select v-model="alarmtype" style="width: 160px;">-->
<!--                <a-select-option value="">请选择</a-select-option>-->
<!--                <a-select-option value="0">困人</a-select-option>-->
<!--                <a-select-option value="1">故障</a-select-option>-->
<!--                <a-select-option value="2">人员伤亡</a-select-option>-->
<!--                <a-select-option value="3">其他</a-select-option>-->
<!--                <a-select-option value="4">投诉</a-select-option>-->
<!--                <a-select-option value="5">咨询</a-select-option>-->
<!--                <a-select-option value="6">打错电话</a-select-option>-->
<!--                <a-select-option value="7">恶意骚扰</a-select-option>-->
<!--                <a-select-option value="8">误报</a-select-option>-->
<!--              </a-select>-->
<!--            </a-form-model-item>-->
            <a-form-model-item v-if="querytype==2" label="区域等级" prop="arealevel" style="margin-left: 5%;">
              <a-select v-model="arealevel">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option :value="2">省</a-select-option>
                <a-select-option :value="4">市</a-select-option>
                <a-select-option :value="6">县</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
          <div style="float: right;margin-top: 9px;width: 30%">
            <a-button @click="resetQueryParams">重置</a-button>
            <a-button type="primary" class="after-btn" @click="initTableData(true)">查询</a-button>
            <a-button class="after-btn" @click="exportData(true)">导出</a-button>
          </div>
        </div>
      </div>
    </div>
    <template>
      <div ref="echarts" class="echars-container" style="margin-top: 10px"></div>
      <div class="box-container sm-container" style="margin-top: 10px;">
        <div class="box-container-inner">
          <a-table :columns="tableColumns" :data-source="tableData" row-key="keyname" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" :pagination="false">
            <span slot="keyname_month" slot-scope="value">
              <span>{{value?moment(value, 'YYYYMM').format('YYYY-MM'):''}}</span>
            </span>
            <span slot="num1" slot-scope="value">
              <span>{{value+" 次"}}</span>
            </span>
            <span slot="num2" slot-scope="value">
              <span>{{value+" 小时"}}</span>
            </span>
            <span slot="num3" slot-scope="value">
              <span>{{value+" 人"}}</span>
            </span>
            <span slot="num4" slot-scope="value">
              <span>{{value+" 次"}}</span>
            </span>
            <span slot="num5" slot-scope="value">
              <span>{{value.toFixed(0)+" 公里"}}</span>
            </span>
          </a-table>
          <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
            <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import * as echarts from 'echarts';
import pagination from '@/mixins/pagination'
import terminaltypes from '@/json/terminaltypes'
import {exportRundataExcel, getRundataStatistic} from "A/bigdata";
export default {
  mixins: [pagination],
  props: {
    interfaceName: {
      default: ''
    },
    querytype: {
      default: ''
    },
    eventtype: {
      default: '1'
    },
  },
  data() {
    return {
      moment,
      ec: null,
      terminaltypes,
      showAdvanced: false,
      queryParams: {
        starttime: '',
        endtime: '',
      },
      alarmtype:'',
      arealevel:'',
      timerange: null,
      allDatas: [],
      tableKey: '',
      tableColumns: [],
      tableData: [],
      tableLoading: false,
      counts:'',
    }
  },
  watch: {
    querytype(val) {
      if(val) {
        this.initTableData();
      }
    },
    timerange(val) {
      if(val && val.length) {
        this.queryParams.starttime = moment(val[0]).format('YYYYMMDD');
        this.queryParams.endtime = moment(val[1]).format('YYYYMMDD');
      }else {
        this.queryParams.starttime = '';
        this.queryParams.endtime = '';
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.ec = echarts.init(this.$refs.echarts);
      this.initTableData();
    },
    resetQueryParams() {
      this.timerange = null;
      this.$refs.queryForm.resetFields();
      this.eventcode=''
      // this.$refs.advancedForm.resetFields();
    },
    initTableData() {
      this.tableColumns = [
        {
          title: '分类',
          dataIndex: 'keyname',
          key: 'keyname',
          ellipsis: true,
        },
        {
          title: '累计运行次数',
          dataIndex: 'num1',
          key: 'num1',
          ellipsis: true,
          scopedSlots: { customRender: 'num1' }
        },
        {
          title: '累计运行时间',
          dataIndex: 'num2',
          key: 'num2',
          ellipsis: true,
          scopedSlots: { customRender: 'num2' }
        },
        {
          title: '累计乘梯人次',
          dataIndex: 'num3',
          key: 'num3',
          ellipsis: true,
          scopedSlots: { customRender: 'num3' }
        },
        {
          title: '累计开关门次数',
          dataIndex: 'num4',
          key: 'num4',
          ellipsis: true,
          scopedSlots: { customRender: 'num4' }
        },
        {
          title: '累计运行里程',
          dataIndex: 'num5',
          key: 'num5',
          ellipsis: true,
          scopedSlots: { customRender: 'num5' }
        },

      ];
      this.tableLoading = true;
      let params = {
        querytype: this.querytype,
        alarmtype: this.alarmtype,
        arealevel:this.arealevel,
        ...this.queryParams,
      };
      getRundataStatistic(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.allDatas = res.item;
          this.pagination.total=res.count;
          this.getTableData();
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableData = this.allDatas.slice((this.currentPage-1)*this.pagination.pageSize, this.currentPage*this.pagination.pageSize)
      this.pkgEchartData();
    },
    pkgEchartData() {
      let xdata = [],series = [];
      let legendName = ['累计运行次数','累计运行时间','累计乘梯人次','累计开关门次数','累计运行里程'];
      let xkey = 'keyname', ykeys = ['num1','num2','num3','num4','num5'], legend = legendName;
      this.tableData.forEach(item => {
        xdata.push(item[xkey]);
      });
      let i=0;
      ykeys.forEach(ykey => {
        let ydata = [];
        this.tableData.forEach(item => {
          ydata.push(item[ykey]);
        })
        series.push({
          name: legendName[i++],
          data: ydata,
          type: 'bar'
        });
      })
      let xLabelWidth = 0;
      try {
        let echartsWidth = parseInt(getComputedStyle(this.$refs.echarts).width);
        let xAxisWidth = echartsWidth * 0.8 - 50;
        xLabelWidth = xAxisWidth/xdata.length;
      }catch(e) {}
      let option = {
        legend: {
          data: legend,
          selected:{
            //图例为‘累计运行时间’的一项默认置灰
            // "累计运行时间":false,
            // "累计运行次数":false,
            // "累计开关门次数":false,
            // "累计乘梯人次":false,
          }
        },
        // grid: {
        //   bottom: 90  
        // },
        xAxis: {
          type: 'category',
          axisLabel: xLabelWidth?{
            interval: 0,
            // rotate: 23,
            width: xLabelWidth,
            overflow: 'truncate',
          }:{},
          data: xdata
        },
        yAxis: {
          type: 'value'
        },
        series: series,
        tooltip: {
          show: true,
          trigger: 'axis'
        }
      };
      this.ec.setOption(option);
    },

    exportData() {
      let params = {
        querytype: this.querytype,
        alarmtype: this.alarmtype,
        arealevel:this.arealevel,
        ...this.queryParams,
      };
      this.showLoading();
      exportRundataExcel(params).then(()=>{
        this.hideLoading();
      }).catch(()=>{
        this.hideLoading();
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.statistic-container {
  height: calc(100vh - 120px);
  overflow-y: auto;
   //border: solid 1px red;
  .echars-container {
    height: 45%;
    background-color: white;
    margin-top: 10px;
    border-radius: 2px;
  }
}
</style>